import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://91de8682486a8a6788ac783fae0baf14@o4507850858823680.ingest.us.sentry.io/4507850863214592",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});