import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Pagination } from "flowbite-react";
import { markNewTerndMinutes } from '@/constants.ts';
import { timeAgoISO, formatISOWithTimezone, formatNumber, isTrendingRecently, encodeStringToUrlFormat, getTrendType } from '@/utils/utils.ts';
import { UpArrow, DownArrow, UpDownArrows } from '@/components/Icons.tsx';
import { Spinner } from '@/components/Spinner.tsx';
import TrendsBase from './TrendsBase.tsx';

interface Props {
    locations: LocationItem[];
}

const TrendsTable: React.FC<Props> = ({ locations }) => {
    const [resultsPerPage, setResultsPerPage] = useState(10);

    // Set results per page for mobile devices
    useEffect(() => {
        if (isMobile) {
            setResultsPerPage(10);
        }
    }, []);

    return (
        <TrendsBase locations={locations}>
            {({
                location,
                currentPage,
                setCurrentPage,
                searchQuery,
                setSearchQuery,
                sortOrder,
                sortColumn,
                handleSort,
                selectedTypes,
                handleFilterChange,
                selectedTypesHistory,
                handleFilterHistoryChange,
                hideNoneEnglish,
                handleHideNoneEnglishChange,
                isFilterActive,
                isHistoryFilterActive,
                loadingFilteredTrend,
                filtered,
                trendsWithoutTypesFilters,
                isPersisted,
                historyFilterName,
                countTrendsByType
            }) => {
                // Pagination calculations
                const totalResults = filtered.length;
                const totalPages = Math.ceil(totalResults / resultsPerPage);
                const startIndex = (currentPage - 1) * resultsPerPage;
                const endIndex = startIndex + resultsPerPage;
                const currentResults = filtered.slice(startIndex, endIndex);

                return (
                    <section className="dark:bg-gray-900 pt-3 sm:py-3 sm:p-5">
                        <div className="mx-auto lg:px-12">
                            <div className="dark:bg-gray-800 bg-zinc-50 relative shadow-md sm:rounded-lg overflow-hidden border-2 dark:border-gray-800">
                                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-1 sm:p-4">
                                    <div className="flex justify-between items-center w-full">
                                        {/* Search */}
                                        <form id="tableSearch" className="flex items-center w-full sm:w-1/4">
                                            <label htmlFor="table-search" className="sr-only">Search</label>
                                            <div className="relative w-full">
                                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                    <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                                                    </svg>
                                                </div>
                                                <input
                                                    type="text"
                                                    id="table-search"
                                                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-9 py-1.5 sm:py-2 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                    placeholder="Search"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                            </div>
                                        </form>

                                        {/* Filters and Time Picker */}
                                        <div className="flex items-center space-x-6 ml-auto">
                                            {/* Filters Button */}
                                            <button
                                                id="filterDropdownButton"
                                                data-dropdown-toggle="filterDropdown"
                                                aria-label="Filter by type"
                                                className={`w-full md:w-auto flex items-center justify-center py-1.5 sm:py-2 sm:px-6 text-sm font-medium focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:text-gray-400 dark:focus:ring-gray-700 dark:bg-gray-800 dark:border-gray-600 ${isFilterActive ? 'text-purple-700 dark:text-purple-400' : 'text-gray-500 dark:text-gray-400'}`}
                                                type="button"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`size-5 -ml-1 mr-2 ${isFilterActive ? 'text-purple-700 dark:text-purple-400' : 'text-gray-500 dark:text-gray-400'}`}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                                                </svg>
                                                Filter
                                            </button>

                                            {/* Filter Dropdown */}
                                            <div id="filterDropdown" className="z-10 hidden w-48 p-3 bg-white rounded-lg shadow divide-y divide-gray-100 dark:bg-gray-900">
                                                <ul className="space-y-2 pb-2 text-sm" aria-labelledby="filterDropdownButton">
                                                    <h2 className="mb-3 text-sm font-medium text-gray-900 dark:text-white">Filter by Type</h2>
                                                    {["Topic", "Hashtag", "Cashtag"].map((type) => (
                                                        <li key={type} className="flex items-center">
                                                            <input
                                                                id={type}
                                                                checked={selectedTypes.includes(type)}
                                                                type="checkbox"
                                                                onChange={(e) => handleFilterChange(e, type)}
                                                                className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                            />
                                                            <label htmlFor={type} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                                                                {type} ({countTrendsByType(trendsWithoutTypesFilters, type)})
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <label className="block items-center py-1 cursor-pointer">
                                                    <h2 className="mb-2 text-sm font-medium text-gray-900 dark:text-white">Show English only</h2>
                                                    <input
                                                        type="checkbox"
                                                        checked={hideNoneEnglish}
                                                        onChange={handleHideNoneEnglishChange}
                                                        className="sr-only peer"
                                                    />
                                                    <div className="relative w-9 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                </label>
                                            </div>

                                            {/* Time Filter Button */}
                                            <button
                                                id="filterDropdownButtonhistory"
                                                data-dropdown-toggle="filterDropdownhistory"
                                                aria-label="Filter by type history"
                                                className={`w-full md:w-auto flex items-center justify-center py-1.5 sm:py-2 sm:px-6 text-sm font-medium focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:border-gray-600 ${
                                                    isHistoryFilterActive 
                                                        ? isPersisted 
                                                            ? 'text-red-900 dark:text-red-700' 
                                                            : 'text-purple-700 dark:text-purple-600'
                                                        : 'text-gray-500 dark:text-gray-400'
                                                }`}
                                                type="button"
                                            >
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    fill="none" 
                                                    viewBox="0 0 24 24" 
                                                    strokeWidth="1.5" 
                                                    stroke="currentColor" 
                                                    className={`size-5 -ml-1 mr-2 ${
                                                        isHistoryFilterActive 
                                                            ? isPersisted 
                                                                ? 'text-red-700 dark:text-red-600' 
                                                                : 'text-purple-700 dark:text-purple-600'
                                                            : 'text-gray-500 dark:text-gray-400'
                                                    }`}
                                                >
                                                    <path 
                                                        strokeLinecap="round" 
                                                        strokeLinejoin="round" 
                                                        d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" 
                                                    />
                                                </svg>
                                                {historyFilterName}
                                            </button>

                                            {/* Time Filter Dropdown */}
                                            <div id="filterDropdownhistory" className="z-10 hidden w-48 p-3 bg-white rounded-lg shadow divide-y divide-gray-100 dark:bg-gray-900">
                                                <ul className="space-y-2 pb-2 text-sm">
                                                    <div className="flex items-center mb-3">
                                                        <h2 className="text-sm font-medium text-gray-900 dark:text-white flex items-center">
                                                            Select Time
                                                            <button data-tooltip-target="tooltip-time" type="button" className="ml-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                                                </svg>
                                                            </button>
                                                            <div id="tooltip-time" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                                                <div className="whitespace-nowrap">Double click to persist time filter</div>
                                                                <div className="tooltip-arrow" data-popper-arrow></div>
                                                            </div>
                                                        </h2>
                                                    </div>
                                                    {["Now", "Today", "Yesterday", "This week", "This month"].map((type) => (
                                                        <li key={type} className="flex items-center">
                                                            <input
                                                                id={type}
                                                                type="checkbox"
                                                                checked={selectedTypesHistory === type}
                                                                onChange={() => handleFilterHistoryChange(type)}
                                                                className={`w-4 h-4 bg-gray-100 border-gray-300 rounded ${
                                                                    selectedTypesHistory === type 
                                                                        ? isPersisted
                                                                            ? 'text-red-900 focus:ring-red-700 dark:focus:ring-red-700 ring-2 ring-red-700 dark:ring-red-700'
                                                                            : 'text-purple-700 focus:ring-purple-700 dark:focus:ring-purple-600'
                                                                        : 'text-gray-600 focus:ring-gray-600 dark:focus:ring-gray-400'
                                                        } dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500`}
                                                            />
                                                            <label 
                                                                htmlFor={type} 
                                                                className={`ml-2 text-sm font-medium ${
                                                                    selectedTypesHistory === type
                                                                        ? isPersisted
                                                                            ? 'text-red-900 dark:text-red-700'
                                                                            : 'text-purple-700 dark:text-purple-600'
                                                                        : 'text-gray-900 dark:text-gray-100'
                                                                }`}
                                                            >
                                                                {type}
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Table */}
                                <div className="overflow-x-auto min-h-80">
                                    {loadingFilteredTrend ? (
                                        <div className="flex justify-center items-center min-h-80">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <table className="w-full bg-zinc-100 border-t dark:border-gray-500 text-sm text-left text-gray-500">
                                            <thead className="text-xxs sm:text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-white">
                                                <tr>
                                                    <th scope="col" className="px-2">
                                                        <div className="flex items-center">
                                                            Rank
                                                            <button onClick={() => handleSort("rank")} aria-label="Sort by rank" className="ms-1.5">
                                                                {sortColumn === "rank" ? (sortOrder === 'asc' ? <UpArrow /> : <DownArrow />) : <UpDownArrows />}
                                                            </button>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-4">
                                                        <div className="flex items-center">
                                                            Trend
                                                            <button onClick={() => handleSort("name")} aria-label="Sort by trend name" className="ms-1.5">
                                                                {sortColumn === "name" ? (sortOrder === 'asc' ? <UpArrow /> : <DownArrow />) : <UpDownArrows />}
                                                            </button>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-4 py-1 sm:py-3">
                                                        <div className="flex items-center">
                                                            Tweet Volume
                                                            <button onClick={() => handleSort("tweet_volume")} aria-label="Sort by tweet volume" className="ms-1.5">
                                                                {sortColumn === "tweet_volume" ? (sortOrder === 'asc' ? <UpArrow /> : <DownArrow />) : <UpDownArrows />}
                                                            </button>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-4">
                                                        <div className="flex items-center">
                                                            Type
                                                            <button onClick={() => handleSort("type")} aria-label="Sort by trend type" className="ms-1.5">
                                                                {sortColumn === "type" ? (sortOrder === 'asc' ? <UpArrow /> : <DownArrow />) : <UpDownArrows />}
                                                            </button>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-4">
                                                        <div className="flex items-center">
                                                            Started trending
                                                            <button onClick={() => handleSort("started_trending")} aria-label="Sort by trend start date" className="ms-1.5">
                                                                {sortColumn === "started_trending" ? (sortOrder === 'asc' ? <UpArrow /> : <DownArrow />) : <UpDownArrows />}
                                                            </button>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            {/* Body */}
                                            <tbody>
                                                {currentResults.length > 0 ? (
                                                    currentResults.map(trend => (
                                                        <tr key={trend.rank} className="text-xs sm:text-lg max-h-12 overflow-hidden bg-white border-b dark:text-gray-400 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                            <td className="px-6 py-3">
                                                                {trend.rank}
                                                            </td>
                                                            <th scope="row" className="px-4 py-3 text-base sm:text-lg font-medium text-gray-900 whitespace-nowrap">
                                                                <a href={`/${encodeStringToUrlFormat(location.name)}/trend/${encodeURIComponent(encodeURIComponent(trend.name))}`}
                                                                    className="font-medium flex items-center text-gray-900 dark:text-gray-200 hover:text-gray-700 dark:hover:text-gray-500">
                                                                    {trend.name}
                                                                    {isTrendingRecently(trend.started_trending, markNewTerndMinutes) && (
                                                                        <span><span className="bg-blue-100 text-blue-800 text-xxs font-semibold ml-3 px-1 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-100 dark:border-blue-400" title="New Trend">NEW</span></span>
                                                                    )}
                                                                </a>
                                                            </th>
                                                            <td className="px-4 py-3 text-center sm:text-left">
                                                                {trend?.tweet_volume ? formatNumber(trend.tweet_volume, 0) : (trend.tweet_volume = 10000, "10k")}
                                                            </td>
                                                            <td className="px-4 py-3">
                                                                {trend.type || getTrendType(trend.name)}
                                                            </td>
                                                            {trend.started_trending ? (
                                                                <td className="px-4 py-3" title={formatISOWithTimezone(trend.started_trending)}>
                                                                    {timeAgoISO(trend.started_trending)}
                                                                </td>
                                                            ) : (
                                                                <td className="px-4 py-3" title={formatISOWithTimezone(trend.last_updated_at)}>
                                                                    {timeAgoISO(trend.last_updated_at)}
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={5} className="text-center text-lg py-8 text-gray-900 bg-white dark:text-gray-400 dark:bg-gray-800">
                                                            No results found.
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>

                                {/* Pagination */}
                                <nav className="flex flex-col sm:flex-row justify-between items-center space-y-3 md:space-y-0 py-2 sm:py-4 sm:px-2" aria-label="Table navigation">
                                    {totalResults !== 0 && (
                                        <nav className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
                                            <span className="text-xs sm:text-sm font-normal text-gray-500 dark:text-gray-400 px-2 sm:mt-1 mr-2">
                                                Showing
                                                <span className="font-semibold text-gray-900 dark:text-white"> {startIndex + 1}-{Math.min(endIndex, totalResults)} </span>
                                                of
                                                <span className="font-semibold text-gray-900 dark:text-white"> {totalResults} </span>
                                            </span>
                                            <Pagination 
                                                layout="navigation" 
                                                currentPage={currentPage} 
                                                totalPages={totalPages} 
                                                onPageChange={setCurrentPage} 
                                                showIcons 
                                                className="inline-flex items-center -space-x-px"
                                                theme={{
                                                    base: "flex items-center text-sm h-8",
                                                    layout: {
                                                        table: {
                                                            base: "text-sm text-gray-700 dark:text-gray-400",
                                                            span: "flex items-center gap-2"
                                                        }
                                                    }
                                                }}
                                            />
                                        </nav>
                                    )}
                                    <p className="mt-auto text-sm font-normal px-2 py-2 text-gray-500 dark:text-gray-400">
                                        {location.updated_at && (
                                            <>Last update <strong>{timeAgoISO(location.updated_at)}</strong></>
                                        )}
                                    </p>
                                </nav>
                            </div>
                        </div>
                    </section>
                );
            }}
        </TrendsBase>
    );
};

export default TrendsTable; 